/**
 * tab navigation, anchor to tab for PNP CMS
 * CraigE 29-Aug-2023
 */

$(function() {

    const navigateOnTab = function () {
        const url = location.href;
        if (url.hash !== '') {
            const tabRef = url.hash + '-tab';
            if ($(tabRef).length > 0) {
                (new bootstrap.Tab(tabRef)).show();
            }
        }
    }

    navigateOnTab();

    //hash tabs to route straight to the correct tab
    let url = location.href.replace(/\/$/, '');

    if (location.hash) {
        const hash = url.split('#');
        const currentTab = document.querySelector('#tabs button#' + hash[1] + '-tab');
        const curTab = new bootstrap.Tab(currentTab);
        curTab.show();
        url = location.href.replace(/\/#/, '#').replace(/-tab$/, '');
        $("#__anchor").val(hash[1]);
        history.replaceState(null, null, url);
        setTimeout(() => {
            window.scrollTop = 0;
        }, 400);
    }
    // change url based on selected tab
    const selectableTabList = [].slice.call(document.querySelectorAll('button[data-bs-toggle="tab"]'));
    selectableTabList.forEach((selectableTab) => {
        //new bootstrap.Tab(selectableTab);
        selectableTab.addEventListener('click', function () {
            const hash = selectableTab.id.replace(/-tab$/, '');
            const newUrl = (hash === '#overview') ? url.split('#')[0] : url.split('#')[0] + '#' + hash;
            $("#__anchor").val(hash.replace('#', ''));
            history.replaceState(null, null, newUrl);
        });
    });

});
